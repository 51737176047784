import { render, staticRenderFns } from "./video-chat-room-launcher.html?vue&type=template&id=55d8f690&scoped=true&"
import script from "./video-chat-room-launcher.vue?vue&type=script&lang=ts&"
export * from "./video-chat-room-launcher.vue?vue&type=script&lang=ts&"
import style0 from "./video-chat-room-launcher.scss?vue&type=style&index=0&id=55d8f690&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d8f690",
  null
  
)

export default component.exports



import { Component, Prop, Vue } from 'vue-property-decorator';
import { TFile } from '@/_types/file.type';
import IconViewableFile from '@/_modules/icons/components/icon-viewable-file.vue';
import IconDownloadableFile from '@/_modules/icons/components/icon-downloadable-file.vue';
import FileHelper from '@/_helpers/file.helper';

@Component({
  components:
    {
      IconViewableFile,
      IconDownloadableFile
    }
})
export default class EwDocumentList extends Vue {

  @Prop({ type: Array, default: (): TFile[] => [] }) public readonly files: TFile[];

  public isFileViewable(file: TFile): boolean {
    return FileHelper.isFileViewableByFilename(file.filename || '');
  }

  public truncateName(name: string): string {
    const parts = name.split('.');
    parts[0] = parts[0].slice(0, 16);
    return parts.join('.');
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products)?_c('div',{staticClass:"products-list"},[_vm._l((_vm.products),function(product,index){return _c('div',{key:product.id,staticClass:"product-item cur-p",on:{"click":function($event){return _vm.showProductsSlider(index)}}},[_c('div',{staticClass:"product-thumbnail",style:({
        'background-image': product.photo_url ? 'url(' + product.photo_url + ')' : 'none'
      })}),_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name)+" ")])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProductsSliderVisible),expression:"isProductsSliderVisible"}],staticClass:"products-slider-dimmer"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProductsSliderVisible),expression:"isProductsSliderVisible"}],ref:"slider",staticClass:"products-slider"},[_c('div',{staticClass:"products-slider-content"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('button.close'),
          classes: ['vue-tooltip-dark'],
          placement: 'bottom',
          delay: 0,
        }),expression:"{\n          content: $t('button.close'),\n          classes: ['vue-tooltip-dark'],\n          placement: 'bottom',\n          delay: 0,\n        }"}],staticClass:"products-slider-close",on:{"click":function($event){return _vm.hideProductsSlider()}}}),_c('div',{staticClass:"slider-company-title"},[_c('company',{attrs:{"company-type":"default","company":_vm.company}})],1),_c('mu-carousel',{attrs:{"active":_vm.activeSlideNumber,"cycle":false,"hide-indicators":true,"hide-controls":_vm.products.length < 2}},[_c('span',{staticClass:"slide-arrow slide-arrow-right",attrs:{"slot":"right"},slot:"right"}),_c('span',{staticClass:"slide-arrow slide-arrow-left",attrs:{"slot":"left"},slot:"left"}),_vm._l((_vm.products),function(product){return _c('mu-carousel-item',{key:'product-slide_' + product.id,staticClass:"products-slide"},[_c('div',{staticClass:"products-slide-content"},[_c('div',{staticClass:"product-photo",style:({
              'background-image': product.photo_url ? 'url(' + product.photo_url + ')' : 'none'
            })}),_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"product-name"},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('div',{staticClass:"product-description"},[_vm._v(" "+_vm._s(product.description)+" ")])])])])})],2)],1)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
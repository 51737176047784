var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-head-nav"},[_c('div',{staticClass:"nav-part nav-part-left"},[(!_vm.isCompanyOwner)?_c('div',{staticClass:"action"},[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.setFavFromCompanyHeadNav($event)}}},[(_vm.isFavoriteCompany)?_c('iconFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('button.rm_favorites'),
            classes: ['vue-tooltip-dark'],
            placement: 'bottom',
            delay: 0,
          }),expression:"{\n            content: $t('button.rm_favorites'),\n            classes: ['vue-tooltip-dark'],\n            placement: 'bottom',\n            delay: 0,\n          }"}]}):_c('iconUnFavorite',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('button.favorites'),
            classes: ['vue-tooltip-dark'],
            placement: 'bottom',
            delay: 0,
          }),expression:"{\n            content: $t('button.favorites'),\n            classes: ['vue-tooltip-dark'],\n            placement: 'bottom',\n            delay: 0,\n          }"}]})],1)]):_vm._e(),_c('div',{staticClass:"action"},[_c('iconShare',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('button.share'),
          classes: ['vue-tooltip-dark'],
          placement: 'bottom',
          delay: 0,
        }),expression:"{\n          content: $t('button.share'),\n          classes: ['vue-tooltip-dark'],\n          placement: 'bottom',\n          delay: 0,\n        }"}],nativeOn:{"click":function($event){$event.stopPropagation();return _vm.shareCompany($event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isContactTeamMember && _vm.event && _vm.event.is_enabled),expression:"isContactTeamMember && event && event.is_enabled"},{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('meetingRooms.StartBroadcast'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('meetingRooms.StartBroadcast'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-start-broadcast",on:{"click":_vm.onStartBroadcastClick}},[_c('icon-broadcast',{staticClass:"icon"}),(_vm.isPromoBroadcastMenuVisible)?_c('promo-broadcast-menu',{attrs:{"type":_vm.BroadcastType.PROMO_CONTACT}}):_vm._e()],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('meetingRooms.toggleFullScreen'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('meetingRooms.toggleFullScreen'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-toggle-fullscreen",on:{"click":_vm.onToggleFullScreenClick}},[_c('span',{staticClass:"icon"})]),(_vm.isCompanyOwner || _vm.isContactTeamMember)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('button.edit'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('button.edit'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-edit"},[_c('edit-entity-button',{attrs:{"isForcedVisible":_vm.isCompanyOwner || _vm.isContactTeamMember,"target-route-name":"promo-page-edit-company"}},[_c('span',{staticClass:"icon"},[_c('svg',{attrs:{"width":"16","height":"23","viewBox":"0 0 17 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M14.9036 9.42409C14.5673 9.42409 14.2947 9.69668 14.2947 10.033V16.1215C14.2947 16.4577 14.0221 16.7303 13.6859 16.7303H2.72655C2.39028 16.7303 2.11769 16.4577 2.11769 16.1215V3.94442C2.11769 3.60814 2.39028 3.33555 2.72655 3.33555H10.0328C10.3691 3.33555 10.6416 3.06296 10.6416 2.72669C10.6416 2.39042 10.3691 2.11786 10.0328 2.11786H2.72655C1.71777 2.11786 0.899994 2.93564 0.899994 3.94442V16.1215C0.899994 17.1302 1.71777 17.948 2.72655 17.948H13.6859C14.6947 17.948 15.5125 17.1302 15.5125 16.1215V10.0329C15.5125 9.69668 15.2399 9.42409 14.9036 9.42409Z","fill":"#00B6F8"}}),_c('path',{attrs:{"fill-rule":"nonzero","clip-rule":"evenodd","d":"M14.0342 0.900095C14.6525 0.900024 15.2454 1.14561 15.6826 1.58284C16.5927 2.49309 16.5926 3.96882 15.6823 4.87896L7.66252 12.8988C7.59571 12.9657 7.51419 13.0161 7.42447 13.0461L3.77135 14.2638C3.70928 14.2842 3.64431 14.2947 3.57895 14.2948C3.51374 14.2948 3.44892 14.2842 3.38702 14.2636C3.06798 14.1574 2.89549 13.8126 3.00177 13.4936L4.21946 9.84045C4.24947 9.75091 4.29966 9.66943 4.36619 9.6024L12.386 1.58323C12.822 1.14433 13.4155 0.898311 14.0342 0.900095ZM6.90631 11.9338L14.8214 4.01866C15.0306 3.81017 15.1479 3.52677 15.1471 3.23142C15.1462 2.93763 15.0293 2.65612 14.8218 2.44817C14.3873 2.01287 13.6822 2.01215 13.2469 2.4466L5.32877 10.3617L4.54153 12.7234L6.90631 11.9338Z","fill":"#00B6F8"}})])])])],1):_vm._e(),_c('div',{staticClass:"company-title-top",class:{
        'is-extra-offset': _vm.isCompanyOwner || _vm.isContactTeamMember,
      }},[_vm._v(" "+_vm._s(_vm.company.title)+" ")])]),_c('div',{staticClass:"nav-part nav-part-right"},[(_vm.mediaItems.length)?_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination-arrow pagination-arrow-left",on:{"click":function($event){return _vm.rewind()}}},[_c('icon-chevron-left',{attrs:{"disabled":_vm.activeSlideIndex < 1}})],1),_c('div',{staticClass:"pagination-overflow"},[_c('div',{ref:"company-media-pagination",staticClass:"pagination"},[_vm._l((_vm.mediaItems),function(item){return [_c('div',{staticClass:"item",class:{'active': item.isActive},on:{"click":function($event){_vm.activateMediaItem(_vm.mediaItems.indexOf(item))}}},[_vm._v(" "+_vm._s(_vm.mediaItems.indexOf(item) + 1)+" ")])]})],2)]),_c('button',{staticClass:"pagination-arrow pagination-arrow-right",on:{"click":function($event){return _vm.forward()}}},[_c('icon-chevron-right',{attrs:{"disabled":_vm.activeSlideIndex === _vm.mediaItems.length - 1}})],1)]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('button.close'),
        classes: ['vue-tooltip-dark'],
        placement: 'bottom',
        delay: 0,
      }),expression:"{\n        content: $t('button.close'),\n        classes: ['vue-tooltip-dark'],\n        placement: 'bottom',\n        delay: 0,\n      }"}],staticClass:"action action-close",on:{"click":_vm.close}})])])}
var staticRenderFns = []

export { render, staticRenderFns }
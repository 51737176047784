


import { Vue, Component, Prop } from 'vue-property-decorator';
import {TProduct} from '@/_types/promo-page/product.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import EwAvatarCompany from '@/_modules/standalone-company/components/UI/ew-avatar-company/ew-avatar-company.vue';
import Company from '@/_modules/promo-hall/components/company/company.vue';

@Component({
  components: {
    EwAvatarCompany,
    Company,
  }
})
export default class ProductList extends Vue {

  @Prop({ type: Object })
  public readonly company: TPromoPage;

  public get products(): TProduct[] {
    return (this.company && this.company.products) || [];
  }

  public get companyName(): string {
    return (this.company && this.company.title) || '';
  }

  public isProductsSliderVisible: boolean = false;
  public activeSlideNumber: number = 0;

  public beforeDestroy(): void {
    /* If the slider is open, we have
       to explicitly hide and/or remove it because its element
       has been moved into another place in the DOM
    */
    this.hideProductsSlider();
    this.removeSliderFromBody();
  }

  /* Shows, hides or toggles the slider with full-size
     * products display
     */
  public hideProductsSlider(): void {
    this.isProductsSliderVisible = false;
  }

  public showProductsSlider(startupSlideIndex: number = 0): void {
    this.activeSlideNumber = startupSlideIndex;
    this.isProductsSliderVisible = true;
    this.moveSliderIntoBody();
  }

  /* For independent z-indexation and fixed positioning,
   * lets do a small DOM-manipulation — move the slider
   * into body
  */
  public moveSliderIntoBody(): void {
    const sliderElement: HTMLElement = this.$refs.slider as HTMLElement;

    if (!sliderElement || (sliderElement.parentNode as HTMLElement).tagName.toLowerCase() === 'body') {
      return;
    }

    sliderElement.parentNode.removeChild(sliderElement);
    document.getElementsByTagName('body')[0].appendChild(sliderElement);
  }

  /* Removes the slider from the DOM
   * when productList is itself destroyed
   */
  public removeSliderFromBody(): void {
    const sliderElement: HTMLElement = this.$refs.slider as HTMLElement;

    if (!sliderElement || (sliderElement.parentNode as HTMLElement).tagName.toLowerCase() !== 'body') {
      return;
    }

    sliderElement.parentNode.removeChild(sliderElement);
  }

}

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.3 3.5H4.7C4.1168 3.50089 3.55775 3.73289 3.14537 4.14537C2.73288 4.55771 2.50088 5.1168 2.5 5.7V18.6333C2.50089 19.2165 2.73289 19.7756 3.14537 20.188C3.55771 20.6005 4.1168 20.8325 4.7 20.8333H20.3C20.8832 20.8324 21.4422 20.6004 21.8546 20.188C22.2671 19.7756 22.4991 19.2165 22.5 18.6333V5.7C22.4991 5.1168 22.2671 4.55775 21.8546 4.14537C21.4423 3.73288 20.8832 3.50088 20.3 3.5ZM21.1667 18.6333C21.1667 18.8632 21.0753 19.0836 20.9128 19.2461C20.7503 19.4086 20.5299 19.5 20.3 19.5H4.7C4.47008 19.5 4.24971 19.4086 4.0872 19.2461C3.9247 19.0836 3.83333 18.8632 3.83333 18.6333V8.83333H21.1667L21.1667 18.6333ZM21.1667 7.5H3.83333V5.7C3.83333 5.47009 3.9247 5.24971 4.0872 5.0872C4.2497 4.9247 4.4701 4.83333 4.7 4.83333H20.3C20.5299 4.83333 20.7503 4.9247 20.9128 5.0872C21.0753 5.2497 21.1667 5.4701 21.1667 5.7L21.1667 7.5Z" fill="white" fill-opacity="0.95"/>
    <path d="M6.49996 6.16667C6.49996 6.53482 6.20145 6.83333 5.83329 6.83333C5.46514 6.83333 5.16663 6.53482 5.16663 6.16667C5.16663 5.79851 5.46514 5.5 5.83329 5.5C6.20145 5.5 6.49996 5.79851 6.49996 6.16667Z" fill="white" fill-opacity="0.95"/>
    <path d="M8.49996 6.16667C8.49996 6.53482 8.20145 6.83333 7.83329 6.83333C7.46514 6.83333 7.16663 6.53482 7.16663 6.16667C7.16663 5.79851 7.46514 5.5 7.83329 5.5C8.20145 5.5 8.49996 5.79851 8.49996 6.16667Z" fill="white" fill-opacity="0.95"/>
    <path d="M10.5 6.16667C10.5 6.53482 10.2014 6.83333 9.83329 6.83333C9.46514 6.83333 9.16663 6.53482 9.16663 6.16667C9.16663 5.79851 9.46514 5.5 9.83329 5.5C10.2014 5.5 10.5 5.79851 10.5 6.16667Z" fill="white" fill-opacity="0.95"/>
    <path d="M10.4547 17.5815C10.643 17.6858 10.8558 17.7379 11.071 17.7324C11.2864 17.7268 11.4962 17.6635 11.6788 17.5495L15.4548 15.1896L15.4547 15.1894C15.8073 14.9689 16.0215 14.5823 16.0215 14.1665C16.0215 13.7506 15.8073 13.364 15.4547 13.1434L11.6786 10.784H11.6788C11.4341 10.6312 11.1429 10.5711 10.8578 10.6144C10.5727 10.6579 10.3126 10.8021 10.1246 11.0208C9.93665 11.2396 9.83337 11.5184 9.83337 11.8068V16.5268C9.83337 16.7421 9.89096 16.9535 10.0003 17.1389C10.1096 17.3243 10.2664 17.4771 10.4547 17.5816L10.4547 17.5815ZM11.1667 12.0333L14.5753 14.1667L11.1667 16.3V12.0333Z" fill="white" fill-opacity="0.95"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconEwVideoCall = Vue.extend({
  name: 'icon-ew-video-call',
});
export default IconEwVideoCall;
</script>

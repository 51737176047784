<template>
  <div>
    <div class="field-header">
      <div class="title">{{$t('title.documents')}}</div>
      <div class="action">
        <a class="btn cur-p" @click="toggleAllDocuments()">{{$t('button.seeAll')}} </a>
      </div>
    </div>
    <div class="field-content">
      <div class="content-item flex-column-center"
           v-for="(doc, key) in docList"
           :key="key"
           v-show="key < 4 || showAllDocs"
      >
        <a :href="doc.url" :download="doc.filename" target="_blank">
          <div class="avatar square-radius m-auto">
            <img
                src="../../../../assets/images/icons/icon-file.svg"
                alt="Document icon"
            />
          </div>
          <div class="title doc-filename"
               :class="{
                     'doc-filename-long-title': docName(doc.filename).length > 0
                   }"
          >
            <span class="filename-short"><truncate :length="26" :text="docName(doc.filename)" clamp=""
                                                   less=""></truncate></span>
            <span class="filename-full" v-if="docName(doc.filename).length > 0">{{ docName(doc.filename) }}<span
                style="display: block;">{{docType(doc.filename)}}</span></span>
          </div>
          <div class="subtitle doc-fileext">{{ docType(doc.filename) }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { FileNameHelper } from '@/helpers/filename.helper';
import truncate from 'vue-truncate-collapsed';

export default {
  name: 'documentList',
  components: {truncate},
  props: {
    docs: {
      type: Array,
      default: function () {
        return [];
      }
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    docs: {
      deep: true,
      immediate: true,
      handler(){
        this.docList = this.docs;
      }
    },
    showAll: {
      immediate: true,
      handler(){
        this.showAllDocs = this.showAll;
      }
    }
  },
  data: function(){
    return {
      showAllDocs: false,
      docList: [],
    }
  },
  methods: {
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    toggleAllDocuments() {
      this.$emit('seeAllDocuments');
    },
  }
}
</script>

<style scoped lang="scss">
  .field-block-documents {
    .field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 8px;

      .title {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: $ew-black;
        opacity: 0.8;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          left: -25px;
          bottom: 2px;
          content: "";
          border-bottom: 1px solid #eaeaea;
          width: 25px; /* Have to use precise units because we have overflow: visible on some field-blocks */
        }
      }

      .btn {
        text-align: left;
        font-size: 12px;
        color: $ew-blue;
        display: none;
      }

    }

    &.show-action {
      .btn {
        display: block;
      }
    }

    .field-content {

      display: flex;
      flex-flow: row wrap;

      .content-item {
        margin-bottom: 26px;
        text-align: left;
        width: 25%;
        word-wrap: break-word;
      }

      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        margin-top: 6px;
        max-width: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .title,
      .subtitle {
        text-align: center;
        font-weight: 300;
        font-size: 10px;
        color: $ew-black;
        opacity: 0.8;
      }

    }


    .doc-filename-long-title {

      position: relative;
      z-index: 2;

      .filename-full {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 8px + 8px);
        padding: 8px 8px 16px;
        margin: -8px;
        background-color: #00d;
        color: $ew-white;
        z-index: 10;
        border-radius: 3px;
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
      }

    }

    &.field-block {
      overflow: visible; /* Тестировщик захотел, чтобы длинные названия файлов были по ховеру, а они могут вылезать */
    }
  }

  /* This rule needs to be here because of the linter «no descending specificity» rule */
  .field-block-documents .field-content .content-item:hover {
    .filename-full {
      display: block;
    }
  }

</style>

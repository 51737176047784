


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import IconUnpinInline from '@/_modules/icons/components/icon-unpin-inline.vue';

@Component({
  components: {
    StreamPlayer,
    IconUnpinInline,
  },
})
export default class CompanyMediaBlock extends Vue {

  @Getter('promoStore/unpinnedMediaItems') unpinnedMediaItems: TMediaItem[];
  @Getter('promoStore/promoPageByExternalId') promoPageByExternalId: (externalId: string) => TPromoPage;

  @Action('promoStore/unpinMediaItem') unpinMediaItem: (params: { externalId: string; id: string }) => void;
  @Action('promoStore/pinMediaItem') pinMediaItem: (params: { externalId: string; id: string }) => void;

  @Prop({ type: String })
  public readonly externalId: string;

  @Prop({ type: Number })
  public readonly propSlideIndex: number;

  @Prop({ type: Boolean, default: false })
  public readonly disableControls: boolean;

  public activeSlideIndex: number = 0;
  public mediaItems: TMediaItem[] = [];
  public activeMediaItem: TMediaItem = null;

  public get isUnpinButtonDisabled(): boolean {
    return !this.activeMediaItem || this.activeMediaItem.type === 'image';
  }

  public get promoPage(): TPromoPage {
    return this.promoPageByExternalId(this.externalId);
  }

  public get companyMediaItems(): TMediaItem[] {
    const promoPage = this.promoPage;
    if (!promoPage || !promoPage.companyMediaItems) {
      return [];
    }
    return promoPage.companyMediaItems;
  }

  public mounted(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  public onUnpinClick(): void {
    if (!this.activeMediaItem) {
      return;
    }
    const node = document.getElementById('company-media-item-' + this.activeMediaItem.id);

    this.unpinMediaItem({
      externalId: this.externalId,
      id: this.activeMediaItem.id,
    });

    if (node && (this.activeMediaItem.type === 'video' || this.activeMediaItem.type === 'embed')) {
      this.$nextTick(() => {
        const container = document.getElementById('media-item-container-' + this.activeMediaItem.id);
        if (container) {
          container.append(node);
        }
      });
    }
  }

  public onCarouselItemChange(index: number): void {
    this.activeSlideIndex = index;

    this.stopAllVideos();

    this.$nextTick(() => {
      if (!this.$refs.carouselItems) {
        return;
      }
      const activeCarouselItem: any = (this.$refs.carouselItems as any[]).find((component: any) => {
        return !!component.active;
      });
      if (!activeCarouselItem) {
        return;
      }
      const activeCarouselItemId = activeCarouselItem.$el.getAttribute('id');
      const activeMediaItem = this.companyMediaItems.find((mediaItem: TMediaItem) => {
        return mediaItem.id === activeCarouselItemId;
      });

      if (activeMediaItem) {
        this.$store.dispatch('promoStore/setActiveCompanyMediaItem', {
          externalId: this.externalId,
          id: activeMediaItem.id,
        });
      }
    });
  }

  public toggleVideoPlay(event: MouseEvent): void {
    let vidEl = event.target as HTMLVideoElement;
    if (vidEl.tagName.toLowerCase() !== 'video') {
      vidEl = vidEl.childNodes[0] as HTMLVideoElement;
    }
    try {
      if (vidEl.paused) {
        vidEl.play();
      } else {
        vidEl.pause();
      }
    } catch (error) {
      // ?
    }
  }

  public onVideoPlayPauseChange(item: TMediaItem, isPaused: boolean): void {
    this.$store.dispatch('promoStore/setCompanyMediaItemIsPaused', {
      externalId: this.externalId,
      id: item.id,
      isPaused: isPaused,
    });
  }

  @Watch('promoPage', { immediate: true, deep: true })
  private onPromoPageChange(): void {
    this.$nextTick(() => {
      this.initActiveMediaItem();
    });
  }

  public initActiveMediaItem(): void {
    if (!this.promoPage) {
      this.activeMediaItem = null;
    }

    if (this.promoPage.activeMediaItem) {
      this.activeMediaItem = this.promoPage.activeMediaItem;
    }

    this.activeMediaItem = this.promoPage.companyMediaItems ? this.promoPage.companyMediaItems[this.activeSlideIndex] : null;
  }

  @Watch('unpinnedMediaItems', { immediate: false })
  private onUnpinnedMediaItemsChange(): void {
    this.onCarouselItemChange(this.activeSlideIndex);
  }

  @Watch('propSlideIndex',)
  private onPropSlideIndexChange(): void {
    if (this.propSlideIndex !== null) { this.activeSlideIndex = this.propSlideIndex; }
  }

  @Watch('companyMediaItems', { immediate: true, deep: true })
  private onCompanyMediaItemsChange(): void {
    const promoPage = this.promoPage;

    if (!promoPage || !promoPage.companyMediaItems) {
      this.mediaItems = [];
      this.activeSlideIndex = 0;
      return;
    }

    const currentBroadcastIds = this.mediaItems.reduce((acc, item) => {
      if (item.type === 'broadcast') {
        acc.push(item.id);
      }
      return acc;
    }, []);

    this.mediaItems = [...promoPage.companyMediaItems.filter((mediaItem: TMediaItem) => {
      return !mediaItem.isUnpinned;
    })];

    this.activeSlideIndex = Math.max(0, Math.min(this.activeSlideIndex, this.mediaItems.length - 1));

    /* choose new recent broadcast */
    if (this.mediaItems.length > 0) {
      const currentItem: TMediaItem = this.mediaItems[this.activeSlideIndex];
      if (currentItem.type !== 'broadcast') {
        for (let i = this.mediaItems.length - 1; i >= 0; i--) {
          if (
            this.mediaItems[i].type === 'broadcast'
            && currentBroadcastIds.indexOf(this.mediaItems[i].id) === -1
          ) {
            this.activeSlideIndex = i;
            break;
          }
        }
      }
    }
  }

  // @Watch('companyMediaItems', { immediate: true })
  // private onCompanyMediaItemsChange(): void {
  //   this.updateMediaItems();
  // }

  private stopAllVideos(): void {
    const allVideos = this.$refs.videoSlideContents as Element[];
    if (!allVideos) { // AW-413
      return;
    }
    for (const video of allVideos) {
      (video as HTMLVideoElement).pause();
    }
    this.$store.dispatch('promoStore/setAllCompanyVideoMediaItemsIsPaused', {
      externalId: this.externalId,
    });
  }
}


<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.1818 21H5.72724C5.27559 21 4.90906 20.6335 4.90906 20.1818V8.7273C4.90906 8.27565 5.27559 7.90912 5.72724 7.90912H22.1818C22.6334 7.90912 23 8.27565 23 8.7273V20.1818C23 20.6335 22.6334 21 22.1818 21ZM6.54542 19.3637H21.3636V9.54548H6.54542V19.3637Z"
      fill="white" fill-opacity="0.95"/>
    <path
      d="M2.45455 12.8182H0.818182C0.366536 12.8182 0 12.4516 0 12V3.81818C0 3.36654 0.366536 3 0.818182 3H9C9.45165 3 9.81818 3.36654 9.81818 3.81818V5.45455C9.81818 5.90619 9.45165 6.27273 9 6.27273C8.54835 6.27273 8.18182 5.90619 8.18182 5.45455V4.63636H1.63636V11.1818H2.45455C2.90619 11.1818 3.27273 11.5484 3.27273 12C3.27273 12.4516 2.90619 12.8182 2.45455 12.8182Z"
      fill="white" fill-opacity="0.95"/>
    <path
      d="M12.4 17.0764C12.4 17.2251 12.5565 17.3218 12.6895 17.2553L16.0423 15.5789C16.1897 15.5052 16.1897 15.2948 16.0423 15.2211L12.6895 13.5447C12.5565 13.4783 12.4 13.575 12.4 13.7236V17.0764Z"
      fill="white" fill-opacity="0.95"/>
    <rect x="6" y="10.7" width="16" height="1.4" fill="white" fill-opacity="0.95"/>
  </svg>
</template>
<script type="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IconUnpinInline extends Vue {

}
</script>




import {Vue, Component, Prop, Watch, Ref} from 'vue-property-decorator';
import {TTab} from '@/_ui/tabs/types/tabs.type';

@Component
export default class Tabs extends Vue {
  @Ref('tabs-wrapper') tabsWrapper: HTMLElement;
  @Ref('tabs-underline') tabsUnderline: HTMLElement;

  @Prop({type: Array, default: (): TTab[] => []})
  public tabList: TTab[];

  public get activeTabNumber(): number {
    return this.tabList.findIndex(tab => tab.isActive) || 0;
  }

  @Watch('activeTabNumber', {immediate: true})
  private onActiveTabNumberChange(): void {
    this.moveTabUnderline();
  }

  public mounted(): void {
    window.setTimeout(() => {
      this.moveTabUnderline();
    }, 500);
    this.initOnResize();
  }

  public beforeDestroy(): void {
    this.stopOnResize();
  }

  public emitActiveTab(tab: TTab, index: number): void {
    this.$emit('active-tab', tab, index);
  }

  public moveTabUnderline(): void {
    if (!this.tabsWrapper) {
      return;
    }
    const tabsWrapper: HTMLElement = (this.tabsWrapper as unknown as Vue).$el as HTMLElement || this.tabsWrapper;
    const tabs: HTMLElement[] = this.filterChildrenByClassName(tabsWrapper.children, 'tab');
    const activeTab: HTMLElement = tabs[this.activeTabNumber] as HTMLElement;
    const rect = activeTab.getBoundingClientRect();
    this.tabsUnderline.style.width = rect.width + 'px';
    this.tabsUnderline.style.marginLeft = activeTab.offsetLeft + 'px';

    this.isOnResizeRequested = false;
  }

  public filterChildrenByClassName(elementCollection: HTMLCollection, className?: string): HTMLElement[] {
    const result: HTMLElement[] = [];
    for (let i = 0; i < elementCollection.length; i++) {
      if (!className || elementCollection[i].classList.contains(className)) {
        result.push(elementCollection[i] as HTMLElement);
      }
    }
    return result;
  }

  public getTabClasses(tab: TTab): { [key: string]: boolean } {
    const result: { [key: string]: boolean } = {
      active: tab.isActive,
    };

    if (tab.extraClasses) {
      const extraClasses: string[] = tab.extraClasses.split(/\s+/);
      extraClasses.forEach(className => {
        result[className] = true;
      });
    }

    return result;
  }

  public initOnResize(): void {
    window.addEventListener('resize', this.onResize);
  }

  public stopOnResize(): void {
    window.removeEventListener('resize', this.onResize);
  }

  public isOnResizeRequested: boolean = false;

  public onResize(): void {
    if (this.isOnResizeRequested) {
      return;
    }
    this.isOnResizeRequested = true;
    window.requestAnimationFrame(this.moveTabUnderline);
  }
}


<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M3.25 3C3.25 2.0335 4.0335 1.25 5 1.25H15.3107L20.75 6.68934V21C20.75 21.9665 19.9665 22.75 19 22.75H5C4.0335 22.75 3.25 21.9665 3.25 21V3ZM13.25 2.75H4.75V21.25H19.25V8.75H15C14.0335 8.75 13.25 7.9665 13.25 7V2.75ZM19.1893 7.25L14.75 2.81066V7.25H19.1893ZM11.25 15.1893V10.25H12.75V15.1893L14.2929 13.6464L15.3536 14.7071L12 18.0607L8.64645 14.7071L9.70711 13.6464L11.25 15.1893Z"
          fill="#001B24" fill-opacity="0.6"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconDownloadableFile = Vue.extend({
  name: 'icon-downloadable-file',
});
export default IconDownloadableFile;
</script>

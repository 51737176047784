







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ChevronLeft extends Vue {
  @Prop({ type: Boolean, default: false }) disabled: boolean;

  public get isDisabled(): string {
    return this.disabled ? 'rgba(0, 27, 36, 0.2)' : '#02ACEA';
  }
}
